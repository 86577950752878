<template>
  <el-tabs type="border-card" v-model:value="value" @tab-click="handleClick">
    <el-tab-pane label="科目余额表" :cache="false">
      <subjecthis ref="subjectBalance" tongbu="true"></subjecthis>
    </el-tab-pane>
    <el-tab-pane label="总账" :cache="false">
      <allaccounthis ref="generalLedger"></allaccounthis>
    </el-tab-pane>
    <el-tab-pane label="明细账" :cache="false">
      <detailaccounthis ref="detailAccount"></detailaccounthis>
    </el-tab-pane>
    <el-tab-pane label="凭证汇总" :cache="false">
      <allvoucherhis ref="proofCollect"></allvoucherhis>
    </el-tab-pane>
    <!-- <el-tab-pane label="日记账">
      <journalhis ref="fee"></journalhis>
    </el-tab-pane> -->
  </el-tabs>
</template>

<script>
// import subjecthis from "./components/subjecthis.vue"
// import allaccounthis from "./components/allaccounthis.vue"
// import detailaccounthis from "./components/detailaccounthis.vue"
// import allvoucherhis from "./components/allvoucherhis.vue"
// import journalhis from "./components/journalhis.vue"

import subjecthis from "./components/subjectBalance.vue"
import allaccounthis from "./components/generalLedger.vue"
import detailaccounthis from "./components/detailAccount.vue"
import allvoucherhis from "./components/proofCollect.vue"

export default {
  name: "accountBook",
  components:{
    subjecthis,
    allaccounthis,
    detailaccounthis,
    allvoucherhis,
  },
  data() {
    return{
      value:'2'
    }
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '科目余额表'){
        this.$refs.subjectBalance.getList()
      }else if(tab.props.label == '凭证汇总'){
        this.$refs.proofCollect.getList()
      }else if(tab.props.label == '总账'){
        this.$refs.generalLedger.getList()
      }else if(tab.props.label == '明细账'){
        this.$refs.detailAccount.getList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* .el-tabs__item {
  width: 60px;
  background: linear-gradient(19deg, transparent 15px, #58a 0);
}  */
.el-tabs__item{
  width: 50px;
  margin: 0 auto !important;
  height: 60px;
  line-height: 20px;
  writing-mode: vertical-lr;/*从左向右 从右向左是 writing-mode: vertical-rl;*/  
  writing-mode: tb-lr;/*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
  border: 0px !important;
}
.el-tabs__item.is-active {
  color: white !important;
}
.is-active {
  background: linear-gradient(24deg, transparent 20px, #67caf9 0);
  background-color: #f5f7fa !important;
  color: white !important;
  // background: linear-gradient(-135deg, transparent 15px, $color-main 0) top right, linear-gradient(45deg, transparent 15px, $color-main 0) bottom left; background-size: 50%; background-repeat: no-repeat;
}
</style>